import React from 'react';
import Layout from "../../components/layout";

const DaresSalaam = () => {
  return (
    <Layout>
      <h1>Jamhuri</h1>
      <h2>Instructors</h2>
      <p>Sensei Kheri Kivuli - Tanzania Chief Instructor<br/>
        Senpai Bilal Mlenga<br/></p>

      <p><strong>Class Times</strong><br/>
        <strong>Monday</strong> to Friday: 1800hrs - 2100hrs<br/>
        <strong>Saturday:</strong> All self wishing trainees can arrange and meet to do outdoor trainning along
        beaches at evening times.
      </p>

      <p><strong>Price</strong><br/>
        Tsh 10000/- monthly to be paid before beginning of the month. (1 USD is equal to approx. 1500/- Tshs.)
      </p>

      <p><strong>Location</strong><br/>
        Jamhuri Primary School. At the centre of Dar-es-Salaam City. Mnazi Mmoja, along Libya Street, Kitumbini Street
        and Jamhuri Street.</p>

      <hr/>

      <h1>Magomeni</h1>

      <h2>Instructors</h2>
      <p>Senpai Suleiman<br/>
        Senpai Mrisho</p>

      <p><strong>Class Times</strong></p>
      <p>This is normally for people who can't train in the evening.<br/>
        <strong>Monday to Friday:</strong> 0600hrs to 0830hrs.</p>

      <p><strong>Price</strong><br/>
        Tshs 5000/- monthly, to be paid before beginning of the month. (1 USD is equal to approx. 1500/- Tshs.)</p>

      <p><strong>Location</strong><br/>
        Five kilometers from Dar City Centre.</p>
    </Layout>
  );
}

export default DaresSalaam;
